/*  
  Date: 25-09-2023 
  Author: Shubham Sonkar 
  Import the Axios library
*/
import axios from "axios";

/*  
  Date: 25-09-2023 
  Author: Shubham Sonkar
  Set a default configuration for Axios to include credentials with requests
*/
axios.defaults.withCredentials = true;

/*  
  Date: 25-09-2023 
  Author: Shubham Sonkar
  Create and export an instance of Axios with a specific baseURL
*/
export default axios.create({
  /*  
    Date: 25-09-2023 
    Author: Shubham Sonkar
    Set the base URL for the requests
  */
  // baseURL: "https://demobackend.rabs.support", // Use this for demo production
  // baseURL: document.getElementById("API_URL").value, // Use this for local development
  // baseURL: "https://backend.crm.deenpropertiesuae.com", // Use this for local development
      // baseURL: "http://localhost:3003",
  baseURL: "https://backend.crm.nhgulf.com", // Use this for client development
  // baseURL: "https://backend.crm.greymatter.company", // Use this for client production
  // baseURL: "https://backend.crm.propertyking.in", // Use this for client production
});
